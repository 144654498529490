import React, { Component, useEffect, useRef, useState } from 'react';
import { Step, Steps } from 'chakra-ui-steps';
import {HiCamera, HiClock, HiAnnotation, HiTrash, HiUpload, HiCog, HiClipboardList} from 'react-icons/hi';
import styled from "styled-components"
import moment from "moment"
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Tooltip,
    Th,
    Td,
    CardHeader,
    TableContainer, Select, NumberInput, Checkbox,
} from '@chakra-ui/react';
import Combobox from "./Combobox";
import {
    Alert,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    AlertIcon,
    NumberInputStepper,
    NumberInputField,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Switch,
    FormControl,
    useDisclosure,
    FormLabel,
    Box,
    Divider,
    Card,
    CardBody,
    Image,
    ButtonGroup,
    Heading,
    AbsoluteCenter,
    Modal,
    ModalOverlay,
    ModalContent,
    Input,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Flex,
    Textarea
} from '@chakra-ui/react'
import { Camera } from "react-camera-pro";
import {HiArchiveBox, HiListBullet} from 'react-icons/hi2';

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1;
`

const Control = styled.div`
  top: 0;
  position: fixed;
  display: flex;
  right: 0;
  width: 20%;
  min-width: 130px;
  min-height: 130px;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px;
  box-sizing: border-box;
  flex-direction: column-reverse;

  @media (max-aspect-ratio: 1/1) {
    flex-direction: row;
    bottom: 0;
    width: 100%;
    height: 20%;
  }

  @media (max-width: 400px) {
    padding: 10px;
  }
`

const StyledButton = styled.button`
  outline: none;
  color: white;
  opacity: 1;
  background: transparent;
  background-color: transparent;
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-image: none;
  padding: 0;
  text-shadow: 0px 0px 4px black;
  background-position: center center;
  background-repeat: no-repeat;
  pointer-events: auto;
  cursor: pointer;
  z-index: 2;
  filter: invert(100%);
  border: none;

  &:hover {
    opacity: 0.7;
  }
`

const TakePhotoButton = styled(StyledButton)`
  background: url('https://img.icons8.com/ios/50/000000/compact-camera.png');
  background-position: center;
  background-size: 50px;
  background-repeat: no-repeat;
  width: 80px;
  height: 80px;
  border: solid 4px black;
  border-radius: 50%;

  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
`

const ChangeFacingCameraButton = styled(StyledButton)`
  background: url(https://img.icons8.com/ios/50/000000/switch-camera.png);
  background-position: center;
  background-size: 40px;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  padding: 40px;
  &:disabled {
    opacity: 1;
    cursor: default;
  }
  @media (max-width: 400px) {
    padding: 40px 5px;
  }
`

function CameraComponent(props) {
    const [numberOfCameras, setNumberOfCameras] = useState(0)
    const camera = useRef(null)

    return (
        <Wrapper>
            <Camera ref={camera} aspectRatio="cover" numberOfCamerasCallback={setNumberOfCameras} />
            <Control>
                <TakePhotoButton
                    onClick={() => {
                        if (camera.current) {
                            const photo = camera.current.takePhoto()

                            props.onPhotoTaken(photo)
                        }
                    }}
                />
                <ChangeFacingCameraButton
                    disabled={numberOfCameras <= 1}
                    onClick={() => {
                        if (camera.current) {
                            camera.current.switchCamera()
                        }
                    }}
                />
            </Control>
        </Wrapper>
    );
}

const initialState = {
    onlyParts: false,
    saveLoading: false,
    consultation: false,
    cameraModalOpen: false,
    preset: {},
    make: null,
    model: null,
    number: null,
    condition: "new",
    images: [],
    step: 0,
    leftImageManual: false,
    rightImageManual: false,
    serviceCameraModalOpen: false,
    thumbnailImageManual: false,
    behindImageManual: false,
    papersImageManual: false,
    boxImageManual: false,
    serialNumberImageManual: false,
    serviceMap: [],
    restaurationMap: [],
    dialMap: [],
    optionalMap: []
}

const StyledDecision = styled.div`
    margin-top: 32px;
    margin-bottom: 16px;
  
    > div {
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
        padding: 32px;
        border-radius: 5px;
        user-select: none;
        cursor: pointer;
        transition: all 0.3s;
      
        &:hover {
            border-color: black;
        }
      
        > div {
            padding-left: 32px;
            box-sizing: border-box;
          
            p.title {
                font-weight: bold;
                margin-bottom: 8px;
                font-size: 18px;
            }
        }
    }
`

export default class extends Component {
    state = { type: null }

    constructor(props) {
        super(props)

        this.onClose = this.onClose.bind(this)
        this.open = this.open.bind(this)

        this.state = { ...initialState }
    }

    componentDidMount() {
        this.props.onRef(this)
    }

    open() {
        const keys = Object.keys(this.state)
        const stateReset = keys.reduce((acc, v) => ({ ...acc, [v]: undefined }), {})

        this.setState({ ...stateReset, ...initialState, open: true })
    }

    onClose() {
        this.setState({ open: false })
    }

    takePhoto(type) {
        this.setState({ cameraModalOpen: true, open: false, currentPhotoType: type })
    }

    render() {
        if(this.state.serviceCameraModalOpen) return <CameraComponent onPhotoTaken={image => {
            this.setState({ serviceCameraModalOpen: false, open: true, images: [ ...this.state.images, { data: image, description: null } ] })
        }} />

        if(this.state.cameraModalOpen) return <CameraComponent onPhotoTaken={image => {
            const key = `${this.state.currentPhotoType}Image`
            this.setState({ cameraModalOpen: false, open: true, [key]: image, [`${key}Manual`]: false })
        }} />

        const getOptions = key => {
            if(!this.state.preset) return []
            const array = this.state.preset[key]

            if(!array) return []

            if(Array.isArray(array)) return array.map(label => ({ label }))

            return Object.keys(array).map(label => ({ label }))
        }

        const fileUpload = React.createRef()
        const fileUploadService = React.createRef()

        return <Modal closeOnOverlayClick={false} isOpen={this.state.open} onClose={this.onClose}>
            <MissingImageRestaurationDialog functions={functions => this.missingRestaurationFunctions = functions} continue={() => this.setState({ step: 5 })} />
            <MissingImageAlertDialog functions={functions => this.missingAlertFunctions = functions} continue={() => this.setState({ step: 5 })} />
            <UnverifiedImageAlertDialog functions={functions => this.unverifiedAlertFunctions = functions} continue={() => this.setState({ step: 5 })} />
            <ModalOverlay />
            <ModalContent maxW="1100px">
                <ModalHeader>{this.state.type === "repair" ? "Neue Uhr zum Service aufnehmen" : "Neue Uhr inventarisieren"}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Steps activeStep={this.state.step}>
                        {[
                            {
                                label: 'Art',
                                content: <StyledDecision style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: 32 }}>
                                    <div onClick={() => this.setState({ step: 1, type: "inventory" })}>
                                        <HiClipboardList style={{ width: 64, height: 64 }} />
                                        <div>
                                            <p className="title">Uhr zum Inventar hinzufügen</p>
                                            <p className="description">Nehme deine Uhr mit detaillierten Informationen und Fotos auf, um diese in WatchStorage zu verwalten.</p>
                                        </div>
                                    </div>
                                    <div onClick={() => this.setState({ step: 1, type: "repair" })}>
                                        <HiCog style={{ width: 64, height: 64 }} />
                                        <div>
                                            <p className="title">Uhr zum Service erfassen</p>
                                            <p className="description">Erfasse Rahmendaten zu deiner Uhr und dem Schaden, um eine Restauration anzufragen.</p>
                                        </div>
                                    </div>
                                </StyledDecision>
                            },
                            {
                                label: 'Modell',
                                content: <div style={{ display: "grid", gridAutoFlow: "row", gridGap: 16, paddingTop: 16 }}>
                                    {this.state.type === "inventory" && <FormControl>
                                        <FormLabel>Marke</FormLabel>
                                        <Combobox placeholder="bspw. Rolex, Breitling" onInputValueChange={(inputValue, setItems) => {
                                            this.setState({ make: inputValue })

                                            const url = new URL(window.watchstorage.backendUrl + "/api/v1/reference-numbers")

                                            url.searchParams.append("distinct", "make")
                                            url.searchParams.append("make", inputValue)
                                            if(this.state.model) url.searchParams.append("model", this.state.model)
                                            if(this.state.number) url.searchParams.append("number", this.state.number)

                                            fetch(url.href, {
                                                method: "GET",
                                                credentials: "include",
                                                headers: {
                                                    'Content-Type': 'application/json',
                                                    'Authorization': `Bearer ${global.WatchStorage.session.token}`
                                                }
                                            }).then(async result => {
                                                if(result.status === 200) {
                                                    const json = await result.json()

                                                    setItems(json.map(data => ({ ...data, label: data.make })))
                                                } else setItems([])
                                            }).catch(() => setItems([]))
                                        }} selectedItem={this.state.make} inputValue={this.state.make} onSelectedItemChange={data => this.setState({ make: data.make })} />
                                    </FormControl>}
                                    {this.state.type === "inventory" && <FormControl>
                                        <FormLabel>Modell</FormLabel>
                                        <Combobox placeholder="bspw. Daytona" onInputValueChange={(inputValue, setItems) => {
                                            this.setState({ model: inputValue })

                                            const url = new URL(window.watchstorage.backendUrl + "/api/v1/reference-numbers")

                                            url.searchParams.append("distinct", "model")
                                            url.searchParams.append("model", inputValue)
                                            if(this.state.make) url.searchParams.append("make", this.state.make)
                                            if(this.state.number) url.searchParams.append("number", this.state.number)

                                            fetch(url.href, {
                                                method: "GET",
                                                credentials: "include",
                                                headers: {
                                                    'Content-Type': 'application/json',
                                                    'Authorization': `Bearer ${global.WatchStorage.session.token}`
                                                }
                                            }).then(async result => {
                                                if(result.status === 200) {
                                                    const json = await result.json()

                                                    setItems(json.map(data => ({ ...data, label: data.model })))
                                                } else setItems([])
                                            }).catch(() => setItems([]))
                                        }} inputValue={this.state.model} onSelectedItemChange={data => this.setState({ model: data.model, make: data.make || this.state.make })} />
                                    </FormControl>}
                                    {this.state.type === "inventory" && <FormControl>
                                        <FormLabel>Referenznummer</FormLabel>
                                        <Combobox placeholder="Auf dem Gehäuse oder den Papieren der Uhr ersichtlich" onInputValueChange={(inputValue, setItems) => {
                                            this.setState({ number: inputValue })

                                            const url = new URL(window.watchstorage.backendUrl + "/api/v1/reference-numbers")

                                            url.searchParams.append("distinct", "number")
                                            url.searchParams.append("number", inputValue)
                                            if(this.state.make) url.searchParams.append("make", this.state.make)
                                            if(this.state.model) url.searchParams.append("model", this.state.model)

                                            fetch(url.href, {
                                                method: "GET",
                                                credentials: "include",
                                                headers: {
                                                    'Content-Type': 'application/json',
                                                    'Authorization': `Bearer ${global.WatchStorage.session.token}`
                                                }
                                            }).then(async result => {
                                                if(result.status === 200) {
                                                    const json = await result.json()

                                                    setItems(json.map(data => ({ ...data, label: data.number })))
                                                } else setItems([])
                                            }).catch(() => setItems([]))
                                        }} inputValue={this.state.number} onSelectedItemChange={data => {
                                            let otherData = {}

                                            Object.keys(data).forEach(key => {
                                                const value = data[key]

                                                if(value) {
                                                    if(typeof value === "string") {
                                                        otherData[key] = value
                                                    } else if(Array.isArray(value) && value.length >= 1) {
                                                        otherData[key] = value[0]
                                                    } else if(Object.keys(value).length >= 1) {
                                                        otherData[key] = Object.keys(value)[0]
                                                    }
                                                }
                                            })

                                            this.setState({
                                                ...otherData,
                                                referenceNumberId: data.id,
                                                preset: {
                                                    ...data
                                                },
                                                number: data.number,
                                                make: data.make || this.state.make,
                                                model: data.model || this.state.model
                                            })
                                        }} />
                                    </FormControl>}
                                    {this.state.type === "repair" && !this.state.onlyParts && <FormControl>
                                        <FormLabel>Marke</FormLabel>
                                        <Input
                                            size="lg"
                                            placeholder="bspw. Rolex, Breitling"
                                            onChange={value => this.setState({ make: value.target.value })}
                                            value={this.state.make}
                                        />
                                    </FormControl>}
                                    {this.state.type === "repair" && !this.state.onlyParts && <FormControl>
                                        <FormLabel>Modell</FormLabel>
                                        <Input
                                            size="lg"
                                            placeholder="bspw. Daytona"
                                            onChange={value => this.setState({ model: value.target.value })}
                                            value={this.state.model}
                                        />
                                    </FormControl>}
                                    {this.state.type === "repair" && !this.state.onlyParts && <FormControl>
                                        <FormLabel>Referenznummer</FormLabel>
                                        <Input
                                            size="lg"
                                            placeholder="Auf dem Gehäuse oder den Papieren der Uhr ersichtlich"
                                            onChange={value => this.setState({ number: value.target.value })}
                                            value={this.state.number}
                                        />
                                    </FormControl>}
                                    {this.state.type === "repair" && <Checkbox isChecked={this.state.onlyParts} onChange={() => {
                                        this.setState({ onlyParts: !this.state.onlyParts })
                                    }}>Ich sende nur einen Teil einer Uhr ein</Checkbox>}
                                </div> },
                            { label: 'Eigenschaften', content: this.state.type === "inventory" ? <ResponsiveGrid style={{ display: "grid", gridGap: 16, paddingLeft: 2, maxHeight: 768, overflowY: "scroll", paddingRight: 16, paddingBottom: 16, marginTop: 16 }}>
                                <ResponsiveTitle>
                                    <Box position='relative' padding='10'>
                                        <Divider />
                                        <AbsoluteCenter bg='white' px='4'>
                                            Allgemein
                                        </AbsoluteCenter>
                                    </Box>
                                </ResponsiveTitle>
                                    <FormControl>
                                        <FormLabel>Lieferinhalt</FormLabel>
                                        <Combobox
                                            onInputValueChange={value => this.setState({ deliveryContents: value })}
                                            inputValue={this.state.deliveryContents}
                                            items={getOptions("deliveryContents")}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Funktionen</FormLabel>
                                        <Input
                                            size="lg"
                                            onChange={value => this.setState({ functions: value.target.value })}
                                            value={this.state.functions}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Wasserfest</FormLabel>
                                        <Input
                                            size="lg"
                                            onChange={value => this.setState({ housingWaterproof: value.target.value })}
                                            value={this.state.housingWaterproof}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Geschlecht</FormLabel>
                                        <Combobox
                                            onInputValueChange={value => this.setState({ gender: value })}
                                            inputValue={this.state.gender}
                                            items={getOptions("gender")}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Sonstiges</FormLabel>
                                        <Input
                                            size="lg"
                                            onChange={value => this.setState({ others: value.target.value })}
                                            value={this.state.others}
                                        />
                                    </FormControl>
                                    <ResponsiveTitle>
                                        <Box position='relative' padding='10'>
                                            <Divider />
                                            <AbsoluteCenter bg='white' px='4'>
                                                Armband
                                            </AbsoluteCenter>
                                        </Box>
                                    </ResponsiveTitle>
                                    <FormControl>
                                        <FormLabel>Armbandfarbe</FormLabel>
                                        <Combobox
                                            onInputValueChange={value => this.setState({ braceletColor: value })}
                                            inputValue={this.state.braceletColor}
                                            items={getOptions("braceletColor")}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Armbandmaterial</FormLabel>
                                        <Combobox
                                            onInputValueChange={value => this.setState({ braceletMaterial: value })}
                                            inputValue={this.state.braceletMaterial}
                                            items={getOptions("braceletMaterial")}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Armbandlänge</FormLabel>
                                        <Combobox
                                            onInputValueChange={value => this.setState({ braceletLength: value })}
                                            inputValue={this.state.braceletLength}
                                            items={getOptions("braceletLength")}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Armbandstärke</FormLabel>
                                        <Combobox
                                            onInputValueChange={value => this.setState({ braceletStrength: value })}
                                            inputValue={this.state.braceletStrength}
                                            items={getOptions("braceletStrength")}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Armbandschließe</FormLabel>
                                        <Combobox
                                            onInputValueChange={value => this.setState({ braceletClasp: value })}
                                            inputValue={this.state.braceletClasp}
                                            items={getOptions("braceletClasp")}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Armbandschließe Breite</FormLabel>
                                        <Combobox
                                            onInputValueChange={value => this.setState({ braceletClaspWidth: value })}
                                            inputValue={this.state.braceletClaspWidth}
                                            items={getOptions("braceletClaspWidth")}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Armbandschließe Material</FormLabel>
                                        <Combobox
                                            onInputValueChange={value => this.setState({ braceletClaspMaterial: value })}
                                            inputValue={this.state.braceletClaspMaterial}
                                            items={getOptions("braceletClaspMaterial")}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Armbandverschluss Breite</FormLabel>
                                        <Combobox
                                            onInputValueChange={value => this.setState({ braceletSpringbarWidth: value })}
                                            inputValue={this.state.braceletSpringbarWidth}
                                            items={getOptions("braceletSpringbarWidth")}
                                        />
                                    </FormControl>
                                    <ResponsiveTitle>
                                        <Box position='relative' padding='10'>
                                            <Divider />
                                            <AbsoluteCenter bg='white' px='4'>
                                                Gehäuse
                                            </AbsoluteCenter>
                                        </Box>
                                    </ResponsiveTitle>
                                    <FormControl>
                                        <FormLabel>Gehäusedurchmesser</FormLabel>
                                        <Input
                                            size="lg"
                                            onChange={value => this.setState({ housingDiameter: value.target.value })}
                                            value={this.state.housingDiameter}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Gehäuseglas</FormLabel>
                                        <Input
                                            size="lg"
                                            onChange={value => this.setState({ housingGlas: value.target.value })}
                                            value={this.state.housingGlas}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Gehäusehöhe</FormLabel>
                                        <Input
                                            size="lg"
                                            onChange={value => this.setState({ housingHeight: value.target.value })}
                                            value={this.state.housingHeight}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Gehäusematerial</FormLabel>
                                        <Combobox
                                            onInputValueChange={value => this.setState({ housingMaterial: value })}
                                            inputValue={this.state.housingMaterial}
                                            items={getOptions("housingMaterial")}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Lünettenmaterial</FormLabel>
                                        <Combobox
                                            onInputValueChange={value => this.setState({ housingBezelMaterial: value })}
                                            inputValue={this.state.housingBezelMaterial}
                                            items={getOptions("housingBezelMaterial")}
                                        />
                                    </FormControl>
                                    <ResponsiveTitle>
                                        <Box position='relative' padding='10'>
                                            <Divider />
                                            <AbsoluteCenter bg='white' px='4'>
                                                Zifferblatt
                                            </AbsoluteCenter>
                                        </Box>
                                    </ResponsiveTitle>
                                    <FormControl>
                                        <FormLabel>Zifferblatt</FormLabel>
                                        <Combobox
                                            onInputValueChange={value => this.setState({ housingDial: value })}
                                            inputValue={this.state.housingDial}
                                            items={getOptions("housingDial")}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Zifferblattzahlen</FormLabel>
                                        <Combobox
                                            onInputValueChange={value => this.setState({ housingDialNumbers: value })}
                                            inputValue={this.state.housingDialNumbers}
                                            items={getOptions("housingDialNumbers")}
                                        />
                                    </FormControl>
                                    <ResponsiveTitle>
                                        <Box position='relative' padding='10'>
                                            <Divider />
                                            <AbsoluteCenter bg='white' px='4'>
                                                Uhrwerk
                                            </AbsoluteCenter>
                                        </Box>
                                    </ResponsiveTitle>
                                    <FormControl>
                                        <FormLabel>Uhrwerk Steinzahl</FormLabel>
                                        <Combobox
                                            onInputValueChange={value => this.setState({ clockworkStoneAmount: value })}
                                            inputValue={this.state.clockworkStoneAmount}
                                            items={getOptions("clockworkStoneAmount")}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Uhrwerkaufzug</FormLabel>
                                        <Combobox
                                            onInputValueChange={value => this.setState({ clockworkWind: value })}
                                            inputValue={this.state.clockworkWind}
                                            items={getOptions("clockworkWind")}
                                        />
                                    </FormControl>
                                    {/*<FormControl>
                                        <FormLabel>Uhrwerk Grundkaliber</FormLabel>
                                        <Combobox
                                            onInputValueChange={value => this.setState({ clockworkBaseCaliber: value })}
                                            inputValue={this.state.clockworkBaseCaliber}
                                            items={getOptions("clockworkBaseCaliber")}
                                        />
                                    </FormControl>*/}
                                    {/*<FormControl>
                                        <FormLabel>Uhrwerk Ganzrückstellung</FormLabel>
                                        <Combobox
                                            onInputValueChange={value => this.setState({ clockworkGearReserve: value })}
                                            inputValue={this.state.clockworkGearReserve}
                                            items={getOptions("clockworkGearReserve")}
                                        />
                                    </FormControl>*/}
                                    <FormControl>
                                        <FormLabel>Uhrwerk Kaliber</FormLabel>
                                        <Combobox
                                            onInputValueChange={value => this.setState({ clockworkCaliber: value })}
                                            inputValue={this.state.clockworkCaliber}
                                            items={getOptions("clockworkCaliber")}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Uhrwerkschwingung</FormLabel>
                                        <Combobox
                                            onInputValueChange={value => this.setState({ clockworkOscillation: value })}
                                            inputValue={this.state.clockworkOscillation}
                                            items={getOptions("clockworkOscillation")}
                                        />
                                    </FormControl>
                            </ResponsiveGrid> : <ResponsiveGrid style={{ display: "grid", gridGap: 16, paddingLeft: 2, maxHeight: 768, overflowY: "scroll", paddingRight: 16, paddingBottom: 16, marginTop: 16 }}>
                                    <FormControl>
                                        <FormLabel>Armbandmaterial</FormLabel>
                                        <Combobox
                                            onInputValueChange={value => this.setState({ braceletMaterial: value })}
                                            inputValue={this.state.braceletMaterial}
                                            items={[
                                                { label: "Eidechsenleder" },
                                                { label: "Geldgold" },
                                                { label: "Gold/Stahl" },
                                                { label: "Kautschuk" },
                                                { label: "Keramik" },
                                                { label: "Krokodilleder" },
                                                { label: "Kunststoff" },
                                                { label: "Leder" },
                                                { label: "Platin" },
                                                { label: "Rindsleder" },
                                                { label: "Roségold" },
                                                { label: "Rotgold" },
                                                { label: "Satin" },
                                                { label: "Schlangenleder" },
                                                { label: "Silber" },
                                                { label: "Silikon" },
                                                { label: "Stahl" },
                                                { label: "Straußenleder" },
                                                { label: "Textil" },
                                                { label: "Titan" },
                                                { label: "Weißgold" },
                                            ]}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Gehäusematerial</FormLabel>
                                        <Combobox
                                            onInputValueChange={value => this.setState({ housingMaterial: value })}
                                            inputValue={this.state.housingMaterial}
                                            items={[
                                                { label: "Gelbgold" },
                                                { label: "Gold/Stahl" },
                                                { label: "Keramik" },
                                                { label: "Kunststoff" },
                                                { label: "Platin" },
                                                { label: "Roségold" },
                                                { label: "Rotgold" },
                                                { label: "Silber" },
                                                { label: "Stahl" },
                                                { label: "Titan" },
                                                { label: "Weißgold" }
                                            ]}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Uhrwerkaufzug</FormLabel>
                                        <Combobox
                                            onInputValueChange={value => this.setState({ clockworkWind: value })}
                                            inputValue={this.state.clockworkWind}
                                            items={[
                                                { label: "Automatik" },
                                                { label: "Handaufzug" },
                                                { label: "Quarz" }
                                            ]}
                                        />
                                    </FormControl>
                                </ResponsiveGrid>},
                            { label: 'Deine Uhr', content: <ResponsiveGrid style={{ display: "grid", gridGap: 16, paddingLeft: 2, maxHeight: 768, overflowY: "scroll", paddingRight: 16, paddingBottom: 16, marginTop: 16 }}>
                                    {this.state.type === "inventory" && <ResponsiveTitle>
                                        <Box position='relative' padding='10'>
                                            <Divider />
                                            <AbsoluteCenter bg='white' px='4'>
                                                Allgemein
                                            </AbsoluteCenter>
                                        </Box>
                                    </ResponsiveTitle>}
                                    <FormControl>
                                        <FormLabel>Seriennummer</FormLabel>
                                        <Input
                                            size="lg"
                                            onChange={value => this.setState({ serialNumber: value.target.value })}
                                            value={this.state.serialNumber}
                                            isDisabled={this.state.serialNumberUnknown}
                                        />
                                    </FormControl>
                                    <FormControl display='flex' alignItems='center'>
                                        <FormLabel mb='0'>
                                            Seriennummer unbekannt
                                        </FormLabel>
                                        <Switch isChecked={this.state.serialNumberUnknown} size="lg" onChange={e => {
                                            this.setState({ serialNumberUnknown: e.target.checked })
                                        }} />
                                    </FormControl>
                                    {this.state.type === "inventory" && <FormControl>
                                        <FormLabel>Baujahr</FormLabel>
                                        <NumberInput
                                            size="lg"
                                            onChange={value => this.setState({ buildYear: value })}
                                            value={this.state.buildYear}>
                                            <NumberInputField />
                                            <NumberInputStepper>
                                                <NumberIncrementStepper />
                                                <NumberDecrementStepper />
                                            </NumberInputStepper>
                                        </NumberInput>
                                    </FormControl>}
                                    <ResponsiveTitle>
                                        <FormControl>
                                            {this.state.type === "inventory" && <FormLabel>Zustand</FormLabel>}
                                            {this.state.type === "repair" && <FormLabel>Allgemeiner Zustand der Uhr</FormLabel>}
                                            <Select
                                                onChange={event => this.setState({ condition: event.target.value })}
                                                value={this.state.condition}
                                            >
                                                <option value="new">Neu • Ungetragene neue Uhr</option>
                                                <option value="nos">NOS • Ungetragene Uhr</option>
                                                <option value="very_good">Sehr gut • Gebraucht mit minimalen Gebrauchsspuren</option>
                                                <option value="good">Gut • Leichte Gebrauchsspuren • Keine tiefen Kratzer oder Macken</option>
                                                <option value="okay">Befriedigend • Sichtbare Gebrauchsspuren vorhanden • Wurde bereits überholt und ggf. poliert</option>
                                                <option value="sufficient">Ausreichend • Starke Spuren • Revisionsbedürftig</option>
                                                <option value="deficient">Mangelhaft • Funktioniert nicht vollständig • Teile wurden getauscht • Kann nicht originale Teile enthalten</option>
                                            </Select>
                                            <Textarea
                                                placeholder={this.state.type === "inventory" ? "Sonstige Anmerkungen zum Zustand der Uhr" : "Sonstige Anmerkungen zum Servicebedarf der Uhr"}
                                                size="lg"
                                                style={{ marginTop: 16 }}
                                                onChange={value => this.setState({ notes: value.target.value })}
                                                value={this.state.notes}
                                            />
                                            {this.state.type === "repair" && <div style={{ width: "100%", display: "grid", gridGap: 32, gridTemplateColumns: "1fr 1fr", marginTop: 16 }}>
                                                <div style={{ display: "grid", gridAutoFlow: "row", gridGap: 16, height: "min-content" }}>
                                                    <FormLabel>Servicebedarf / Mängel an der Uhr</FormLabel>
                                                    {Object.keys(serviceMap).map((key, index) => <Checkbox isChecked={this.state.serviceMap.includes(key)} onChange={() => {
                                                        if(this.state.serviceMap.includes(key)) this.setState({ serviceMap: this.state.serviceMap.filter(sm => sm !== key) })
                                                        else this.setState({ serviceMap: [ ...this.state.serviceMap, key ] })
                                                    }} key={index}>{serviceMap[key]}</Checkbox>)}
                                                    <FormLabel>Gehäuse und Armband</FormLabel>
                                                    {Object.keys(restaurationMap).map((key, index) => <Checkbox isChecked={this.state.restaurationMap.includes(key)} onChange={() => {
                                                        if(this.state.restaurationMap.includes(key)) this.setState({ restaurationMap: this.state.restaurationMap.filter(sm => sm !== key) })
                                                        else this.setState({ restaurationMap: [ ...this.state.restaurationMap, key ] })
                                                    }} key={index}>{restaurationMap[key]}</Checkbox>)}
                                                </div>
                                                <div style={{ display: "grid", gridAutoFlow: "row", gridGap: 16, height: "min-content" }}>
                                                    <FormLabel>Zifferblatt und Zeiger</FormLabel>
                                                    {Object.keys(dialMap).map((key, index) => <Checkbox isChecked={this.state.dialMap.includes(key)} onChange={() => {
                                                        if(this.state.dialMap.includes(key)) this.setState({ dialMap: this.state.dialMap.filter(sm => sm !== key) })
                                                        else this.setState({ dialMap: [ ...this.state.dialMap, key ] })
                                                    }} key={index}>{dialMap[key]}</Checkbox>)}
                                                    <FormLabel>Optional</FormLabel>
                                                    {Object.keys(optionalMap).map((key, index) => <Checkbox isChecked={this.state.optionalMap.includes(key)} onChange={() => {
                                                        if(this.state.optionalMap.includes(key)) this.setState({ optionalMap: this.state.optionalMap.filter(sm => sm !== key) })
                                                        else this.setState({ optionalMap: [ ...this.state.optionalMap, key ] })
                                                    }} key={index}>{optionalMap[key]}</Checkbox>)}
                                                </div>
                                            </div>}
                                        </FormControl>
                                    </ResponsiveTitle>
                                    {this.state.type === "inventory" && <>
                                    <ResponsiveTitle>
                                        <Box position='relative' padding='10'>
                                            <Divider />
                                            <AbsoluteCenter bg='white' px='4'>
                                                Eigenschaften
                                            </AbsoluteCenter>
                                        </Box>
                                    </ResponsiveTitle>
                                    <FormControl display='flex' alignItems='center'>
                                        <FormLabel mb='0'>
                                            OVP vorhanden
                                        </FormLabel>
                                        <Switch size="lg" onChange={e => {
                                            this.setState({ box: e.target.checked })
                                        }} />
                                    </FormControl>
                                    <FormControl display='flex' alignItems='center'>
                                        <FormLabel mb='0'>
                                            Papiere vorhanden
                                        </FormLabel>
                                        <Switch size="lg" onChange={e => {
                                            this.setState({ papers: e.target.checked })
                                        }} />
                                    </FormControl>
                                    <ResponsiveTitle>
                                        <Box position='relative' padding='10'>
                                            <Divider />
                                            <AbsoluteCenter bg='white' px='4'>
                                                Erwerb
                                            </AbsoluteCenter>
                                        </Box>
                                    </ResponsiveTitle>
                                    <FormControl>
                                        <FormLabel>Kaufpreis in EUR</FormLabel>
                                        <NumberInput
                                            size="lg"
                                            onChange={value => this.setState({ purchasePrice: value })}
                                            value={this.state.purchasePrice}>
                                            <NumberInputField />
                                            <NumberInputStepper>
                                                <NumberIncrementStepper />
                                                <NumberDecrementStepper />
                                            </NumberInputStepper>
                                        </NumberInput>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Kaufdatum</FormLabel>
                                        <Input
                                            size="lg"
                                            type="date"
                                            onChange={value => this.setState({ purchaseDate: value.target.value })}
                                            value={this.state.purchaseDate}
                                        />
                                    </FormControl>
                                    </>}
                                </ResponsiveGrid> },
                            { label: 'Bilder',
                                content: <div style={{ marginTop: 16, width: "100%" }}>
                                    <input
                                        type="file"
                                        ref={fileUpload}
                                        accept="image/*"
                                        onChange={data => {
                                            const getBase64 = file => {
                                                let reader = new FileReader();
                                                reader.readAsDataURL(file);
                                                reader.onload = () => {
                                                    const base64 = reader.result

                                                    const key = `${this.state.currentPhotoType}Image`

                                                    this.setState({ [key]: base64, [`${key}Manual`]: true })
                                                };
                                            };

                                            try {
                                                getBase64(data.target.files[0])
                                            }  catch(e) {}
                                        }}
                                        style={{ opacity: "0", visibility: "hidden" }}
                                    />
                                    <input
                                        type="file"
                                        ref={fileUploadService}
                                        accept="image/*"
                                        onChange={data => {
                                            const getBase64 = file => {
                                                let reader = new FileReader();
                                                reader.readAsDataURL(file);
                                                reader.onload = () => {
                                                    const base64 = reader.result

                                                    this.setState({ images: [ ...this.state.images, { data: base64, description: null } ] })
                                                };
                                            };

                                            try {
                                                getBase64(data.target.files[0])
                                            }  catch(e) {}
                                        }}
                                        style={{ opacity: "0", visibility: "hidden" }}
                                    />
                                    <Alert status='info'>
                                        <AlertIcon />
                                        {this.state.type === "inventory" ? "Das Schießen neuer Bilder direkt durch die App sorgt für eine automatische Verifizierung deiner Uhr. Wenn du bereits geschossene Bilder hochladen möchtest, musst du deine Uhr ggf. auf andere Weise verifizieren." : "Ausführliche Bilder helfen uns beim Erstellen des Kostenvoranschlags. Bitte erstelle Nahaufnahmen von besonderen Stellen, die den Service betreffen (z.B. Macken am Gehäuse oder Flecken auf dem Zifferblatt)."}
                                    </Alert>
                                    <div style={{ marginTop: 16, display: "grid", gridAutoFlow: "row", gridGap: 16 }}>
                                        <PictureComponent
                                            title={this.state.type === "inventory" ? "Bild der Uhr von vorne" : "(Optional) Bild der Uhr von vorne"}
                                            description={this.state.type === "inventory" ? "Dieses Bild wird in der Übersicht deiner Uhren verwendet." : "Dieses Bild dient lediglich deiner persönlichen Übersicht im Portal."}
                                            fileUpload={() => fileUpload}
                                            placeholder="/thumbnail.jpg"
                                            image={this.state.thumbnailImage}
                                            takePhoto={photo => this.takePhoto(photo)}
                                            setImageNull={() => this.setState({ thumbnailImage: null, thumbnailImageManual: false })}
                                            setCurrentPhotoType={currentPhotoType => this.setState({ currentPhotoType })}
                                            field="thumbnail"
                                        />
                                        {this.state.type === "inventory" && <PictureComponent
                                            title="Bild der Uhr von hinten"
                                            description="Dieses Bild ist nur für dich sichtbar und dient der Dokumentation."
                                            fileUpload={() => fileUpload}
                                            placeholder="/behind.webp"
                                            image={this.state.behindImage}
                                            takePhoto={photo => this.takePhoto(photo)}
                                            setImageNull={() => this.setState({ behindImage: null, behindImageManual: false })}
                                            setCurrentPhotoType={currentPhotoType => this.setState({ currentPhotoType })}
                                            field="behind"
                                        />}
                                        {this.state.type === "inventory" && <PictureComponent
                                            title="Bild der Uhr von links"
                                            description="Dieses Bild ist nur für dich sichtbar und dient der Dokumentation."
                                            fileUpload={() => fileUpload}
                                            placeholder="/left.jpg"
                                            image={this.state.leftImage}
                                            takePhoto={photo => this.takePhoto(photo)}
                                            setImageNull={() => this.setState({ leftImage: null, leftImageManual: false })}
                                            setCurrentPhotoType={currentPhotoType => this.setState({ currentPhotoType })}
                                            field="left"
                                        />}
                                        {this.state.type === "inventory" && <PictureComponent
                                            title="Bild der Uhr von rechts"
                                            description="Dieses Bild ist nur für dich sichtbar und dient der Dokumentation."
                                            fileUpload={() => fileUpload}
                                            placeholder="/right.jpg"
                                            image={this.state.rightImage}
                                            takePhoto={photo => this.takePhoto(photo)}
                                            setImageNull={() => this.setState({ rightImage: null, rightImageManual: false })}
                                            setCurrentPhotoType={currentPhotoType => this.setState({ currentPhotoType })}
                                            field="right"
                                        />}
                                        {this.state.type === "inventory" && <PictureComponent
                                            title="Bild der Box / Umverpackung"
                                            description="Dieses Bild ist nur für dich sichtbar und dient der Dokumentation."
                                            fileUpload={() => fileUpload}
                                            placeholder="/box.jpg"
                                            image={this.state.boxImage}
                                            takePhoto={photo => this.takePhoto(photo)}
                                            setImageNull={() => this.setState({ boxImage: null, boxImageManual: false })}
                                            setCurrentPhotoType={currentPhotoType => this.setState({ currentPhotoType })}
                                            field="box"
                                        />}
                                        {this.state.type === "inventory" && <PictureComponent
                                            title="Bild der Papiere inkl. Referenz und Seriennr."
                                            description="Dieses Bild wird später verwendet, um die Echtheit deiner Uhr zu verifizieren."
                                            fileUpload={() => fileUpload}
                                            placeholder="/papers.jpg"
                                            image={this.state.papersImage}
                                            takePhoto={photo => this.takePhoto(photo)}
                                            setImageNull={() => this.setState({ papersImage: null, papersImageManual: false })}
                                            setCurrentPhotoType={currentPhotoType => this.setState({ currentPhotoType })}
                                            field="papers"
                                        />}
                                        {this.state.type === "inventory" && <PictureComponent
                                            title="Bild der Seriennummer auf der Uhr"
                                            description="Dieses Bild wird später verwendet, um die Echtheit deiner Uhr zu verifizieren."
                                            fileUpload={() => fileUpload}
                                            placeholder="/serialNumber.jpg"
                                            image={this.state.serialNumberImage}
                                            takePhoto={photo => this.takePhoto(photo)}
                                            setImageNull={() => this.setState({ serialNumberImage: null, serialNumberImageManual: false })}
                                            setCurrentPhotoType={currentPhotoType => this.setState({ currentPhotoType })}
                                            field="serialNumber"
                                        />}
                                        {this.state.type === "repair" && <div style={{ display: "grid", gridGap: 16, gridTemplateColumns: "1fr 1fr 1fr 1fr" }}>
                                            {this.state.images.map((image, index) => <PictureComponentService
                                                key={index}
                                                isDisabled={this.state.saveLoading}
                                                image={image.data}
                                                description={image.description}
                                                onChange={description => {
                                                    let images = this.state.images.map((img, ind) => {
                                                        if(ind !== index) return img

                                                        return { ...img, description }
                                                    })

                                                    this.setState({ images })
                                                }}
                                                setImageNull={() => this.setState({ images: this.state.images.filter((_, ind) => ind != index) })}
                                            />)}
                                        </div>}
                                        {this.state.type === "repair" && <div style={{ display: "grid", gridAutoFlow: "column", gridGap: 16 }}>
                                            <Button isDisabled={this.state.saveLoading} leftIcon={<HiCamera />} variant='solid' colorScheme='blue' onClick={() => this.setState({ serviceCameraModalOpen: true, open: false })}>
                                                Servicebild mit Kamera schießen
                                            </Button>
                                            <Button isDisabled={this.state.saveLoading} leftIcon={<HiUpload style={{ marginInlineEnd: 4 }} />} variant='outline' colorScheme='blue' onClick={() => {
                                                fileUploadService.current.click()
                                            }}>
                                                Servicebild von Gerät hochladen
                                            </Button>
                                        </div>}
                                    </div>
                                </div> },
                            { label: 'Zusammenfassung', content: <ResponsiveSummary style={{ marginTop: 24, display: "grid", gridGap: 24, maxWidth: 1024 }}>
                                    <div style={{ display: "grid", gridTemplateColumns: "1fr", gridGap: 24 }}>
                                        <Card>
                                            <CardHeader>
                                                <Heading size='md'>Modell</Heading>
                                            </CardHeader>
                                            <CardBody>
                                                <TableContainer>
                                                    <Table size='sm'>
                                                        <Thead>
                                                            <Tr>
                                                                <Th>Eigenschaften</Th>
                                                                <Th>Wert</Th>
                                                            </Tr>
                                                        </Thead>
                                                        <Tbody>
                                                            <Tr>
                                                                <Td>Marke</Td>
                                                                <Td>{this.state.make}</Td>
                                                            </Tr>
                                                            <Tr>
                                                                <Td>Modell</Td>
                                                                <Td>{this.state.model}</Td>
                                                            </Tr>
                                                            <Tr>
                                                                <Td>Referenznummer</Td>
                                                                <Td>{this.state.number}</Td>
                                                            </Tr>
                                                        </Tbody>
                                                    </Table>
                                                </TableContainer>
                                            </CardBody>
                                        </Card>
                                        <Card>
                                            <CardHeader>
                                                <Heading size='md'>Deine Uhr</Heading>
                                            </CardHeader>
                                            <CardBody>
                                                <TableContainer>
                                                    <Table size='sm'>
                                                        <Thead>
                                                            <Tr>
                                                                <Th>Eigenschaften</Th>
                                                                <Th>Wert</Th>
                                                            </Tr>
                                                        </Thead>
                                                        <Tbody>
                                                            <Tr>
                                                                <Td>Seriennummer</Td>
                                                                <Td>{this.state.serialNumber}</Td>
                                                            </Tr>
                                                            {this.state.type === "inventory" && <Tr>
                                                                <Td>Baujahr</Td>
                                                                <Td>{this.state.buildYear}</Td>
                                                            </Tr>}
                                                            <Tr>
                                                                <Td>Zustand</Td>
                                                                <Td>{getShortCondition(this.state.condition)}</Td>
                                                            </Tr>
                                                            <Tr>
                                                                <Td>Sonstige Anmerkungen</Td>
                                                                <Td>{this.state.notes}</Td>
                                                            </Tr>
                                                            {this.state.type === "inventory" && <Tr>
                                                                <Td>OVP vorhanden</Td>
                                                                <Td>{this.state.box ? "Ja" : "Nein"}</Td>
                                                            </Tr>}
                                                            {this.state.type === "inventory" && <Tr>
                                                                <Td>Papiere vorhanden</Td>
                                                                <Td>{this.state.papers ? "Ja" : "Nein"}</Td>
                                                            </Tr>}
                                                            {this.state.type === "inventory" && <Tr>
                                                                <Td>Kaufpreis</Td>
                                                                <Td>{this.state.purchasePrice}</Td>
                                                            </Tr>}
                                                            {this.state.type === "inventory" && <Tr>
                                                                <Td>Kaufdatum</Td>
                                                                <Td>{this.state.purchaseDate ? moment(this.state.purchaseDate).format("DD.MM.YYYY") : "keine Angabe"}</Td>
                                                            </Tr>}
                                                        </Tbody>
                                                    </Table>
                                                </TableContainer>
                                            </CardBody>
                                        </Card>
                                        <Card>
                                            <CardHeader>
                                                <Heading size='md'>Bilder</Heading>
                                            </CardHeader>
                                            <CardBody>
                                                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", gridGap: 24 }}>
                                                    <img src={this.state.thumbnailImage || "/thumbnail.jpg"} style={{ height: 80, width: "100%", objectFit: "cover", filter: this.state.thumbnailImage ? "none" : "grayscale(100%) opacity(20%)" }} />
                                                    {this.state.images.map((image, key) => <img key={key} src={image.data} style={{ height: 80, width: "100%", objectFit: "cover", filter: this.state.thumbnailImage ? "none" : "grayscale(100%) opacity(20%)" }} />)}
                                                    {this.state.type === "inventory" && <img src={this.state.behindImage || "/behind.webp"} style={{ height: 80, width: "100%", objectFit: "cover", filter: this.state.behindImage ? "none" : "grayscale(100%) opacity(20%)" }} />}
                                                    {this.state.type === "inventory" && <img src={this.state.leftImage || "/left.jpg"} style={{ height: 80, width: "100%", objectFit: "cover", filter: this.state.leftImage ? "none" : "grayscale(100%) opacity(20%)" }} />}
                                                    {this.state.type === "inventory" && <img src={this.state.rightImage || "/right.jpg"} style={{ height: 80, width: "100%", objectFit: "cover", filter: this.state.rightImage ? "none" : "grayscale(100%) opacity(20%)" }} />}
                                                    {this.state.type === "inventory" && <img src={this.state.boxImage || "/box.jpg"} style={{ height: 80, width: "100%", objectFit: "cover", filter: this.state.boxImage ? "none" : "grayscale(100%) opacity(20%)" }} />}
                                                    {this.state.type === "inventory" && <img src={this.state.papersImage || "/papers.jpg"} style={{ height: 80, width: "100%", objectFit: "cover", filter: this.state.papersImage ? "none" : "grayscale(100%) opacity(20%)" }} />}
                                                    {this.state.type === "inventory" && <img src={this.state.serialNumberImage || "/serialNumber.jpg"} style={{ height: 80, width: "100%", objectFit: "cover", filter: this.state.serialNumberImage ? "none" : "grayscale(100%) opacity(20%)" }} />}
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                    <div style={{ display: "grid", gridAutoFlow: "row", gridGap: 24 }}>
                                        <Card>
                                            <CardHeader>
                                                <Heading size='md'>Daten</Heading>
                                            </CardHeader>
                                            <CardBody>
                                                <TableContainer>
                                                    <Table size='sm'>
                                                        <Thead>
                                                            <Tr>
                                                                <Th>Eigenschaft</Th>
                                                                <Th>Wert</Th>
                                                            </Tr>
                                                        </Thead>
                                                        <Tbody>
                                                            {this.state.type === "inventory" && <Tr>
                                                                <Td>Lieferinhalt</Td>
                                                                <Td>{this.state.deliveryContents}</Td>
                                                            </Tr>}
                                                            {this.state.type === "inventory" && <Tr>
                                                                <Td>Funktionen</Td>
                                                                <Td>{this.state.functions}</Td>
                                                            </Tr>}
                                                            {this.state.type === "inventory" && <Tr>
                                                                <Td>Wasserfest</Td>
                                                                <Td>{this.state.housingWaterproof}</Td>
                                                            </Tr>}
                                                            {this.state.type === "inventory" && <Tr>
                                                                <Td>Geschlecht</Td>
                                                                <Td>{this.state.gender}</Td>
                                                            </Tr>}
                                                            {this.state.type === "inventory" && <Tr>
                                                                <Td>Sonstiges</Td>
                                                                <Td>{this.state.others}</Td>
                                                            </Tr>}
                                                            {this.state.type === "inventory" && <Tr>
                                                                <Td>Armbandfarbe</Td>
                                                                <Td>{this.state.braceletColor}</Td>
                                                            </Tr>}
                                                            <Tr>
                                                                <Td>Armbandmaterial</Td>
                                                                <Td>{this.state.braceletMaterial}</Td>
                                                            </Tr>
                                                            {this.state.type === "inventory" && <Tr>
                                                                <Td>Armbandlänge</Td>
                                                                <Td>{this.state.braceletLength}</Td>
                                                            </Tr>}
                                                            {this.state.type === "inventory" && <Tr>
                                                                <Td>Armbandstärke</Td>
                                                                <Td>{this.state.braceletStrength}</Td>
                                                            </Tr>}
                                                            {this.state.type === "inventory" && <Tr>
                                                                <Td>Armbandschließe</Td>
                                                                <Td>{this.state.braceletClasp}</Td>
                                                            </Tr>}
                                                            {this.state.type === "inventory" && <Tr>
                                                                <Td>Armbandschließe Breite</Td>
                                                                <Td>{this.state.braceletClaspWidth}</Td>
                                                            </Tr>}
                                                            {this.state.type === "inventory" && <Tr>
                                                                <Td>Armbandschließe Material</Td>
                                                                <Td>{this.state.braceletClaspMaterial}</Td>
                                                            </Tr>}
                                                            {this.state.type === "inventory" && <Tr>
                                                                <Td>Armbandverschluss Breite</Td>
                                                                <Td>{this.state.braceletSpringbarWidth}</Td>
                                                            </Tr>}
                                                            {this.state.type === "inventory" && <Tr>
                                                                <Td>Gehäusedurchmesser</Td>
                                                                <Td>{this.state.housingDiameter}</Td>
                                                            </Tr>}
                                                            {this.state.type === "inventory" && <Tr>
                                                                <Td>Gehäuseglas</Td>
                                                                <Td>{this.state.housingGlas}</Td>
                                                            </Tr>}
                                                            {this.state.type === "inventory" && <Tr>
                                                                <Td>Gehäusehöhe</Td>
                                                                <Td>{this.state.housingHeight}</Td>
                                                            </Tr>}
                                                            <Tr>
                                                                <Td>Gehäusematerial</Td>
                                                                <Td>{this.state.housingMaterial}</Td>
                                                            </Tr>
                                                            {this.state.type === "inventory" && <Tr>
                                                                <Td>Lünettenmaterial</Td>
                                                                <Td>{this.state.housingBezelMaterial}</Td>
                                                            </Tr>}
                                                            {this.state.type === "inventory" && <Tr>
                                                                <Td>Zifferblatt</Td>
                                                                <Td>{this.state.housingDial}</Td>
                                                            </Tr>}
                                                            {this.state.type === "inventory" && <Tr>
                                                                <Td>Zifferblattzahlen</Td>
                                                                <Td>{this.state.housingDialNumbers}</Td>
                                                            </Tr>}
                                                            {this.state.type === "inventory" && <Tr>
                                                                <Td>Uhrwerk Steinzahl</Td>
                                                                <Td>{this.state.clockworkStoneAmount}</Td>
                                                            </Tr>}
                                                            <Tr>
                                                                <Td>Uhrwerkaufzug</Td>
                                                                <Td>{this.state.clockworkWind}</Td>
                                                            </Tr>
                                                            {/*<Tr>
                                                            <Td>Uhrwerk Grundkaliber</Td>
                                                            <Td>{this.state.clockworkBaseCaliber}</Td>
                                                        </Tr>*/}
                                                            {/*<Tr>
                                                            <Td>Uhrwerk Ganzrückstellung</Td>
                                                            <Td>{this.state.clockworkGearReserve}</Td>
                                                        </Tr>*/}
                                                            {this.state.type === "inventory" && <Tr>
                                                                <Td>Uhrwerk Kaliber</Td>
                                                                <Td>{this.state.clockworkCaliber}</Td>
                                                            </Tr>}
                                                            {this.state.type === "inventory" && <Tr>
                                                                <Td>Uhrwerkschwingung</Td>
                                                                <Td>{this.state.clockworkOscillation}</Td>
                                                            </Tr>}
                                                        </Tbody>
                                                    </Table>
                                                </TableContainer>
                                            </CardBody>
                                        </Card>
                                        {this.state.type !== "inventory" && <Card>
                                            <CardHeader>
                                                <Heading size='md'>Informationen</Heading>
                                            </CardHeader>
                                            <CardBody>
                                                <FormControl display='flex' alignItems='center'>
                                                    <FormLabel mb='0'>
                                                        Ich wünsche eine persönliche Beratung zur Bearbeitung meiner Uhr.
                                                    </FormLabel>
                                                    <Switch isChecked={this.state.consultation} size="lg" onChange={e => {
                                                        this.setState({ consultation: e.target.checked })
                                                    }} />
                                                </FormControl>
                                                {this.state.consultation && <FormControl>
                                                    <FormLabel style={{ marginTop: 16 }}>Telefonnummer (optional)</FormLabel>
                                                    <Input
                                                        size="lg"
                                                        placeholder="Die Angabe Ihrer Telefonnr. erleichtert die Beratung"
                                                        onChange={value => this.setState({ telephone: value.target.value })}
                                                        value={this.state.telephone}
                                                    />
                                                </FormControl>}
                                                <Textarea style={{ marginTop: 16 }} value={this.state.description} onChange={e => this.setState({ description: e.target.value })} placeholder="Solltest du noch Anmerkungen / Wünsche zur Anfrage haben, gerne hier notieren." />
                                            </CardBody>
                                        </Card>}
                                    </div>
                            </ResponsiveSummary> }
                        ].map(({ label, content }, index) => (
                            <Step label={label} key={label} onClick={() => {
                                if(index < this.state.step) this.setState({ step: index })
                            }}>
                                {content}
                            </Step>
                        ))}
                    </Steps>

                </ModalBody>
                {this.state.step !== 0 && <ModalFooter>
                    <Button onClick={() => this.setState({ step: this.state.step - 1 })} variant='ghost' style={{ marginRight: 16 }}>
                        Zurück
                    </Button>
                    <Tooltip hasArrow placement="left" label={(this.state.step === 4 && this.state.type === "repair" && this.state.images.length === 0 && !this.state.thumbnailImage) ? "Bitte hänge mindestens ein Servicebild an." : null}><Button isLoading={this.state.saveLoading} isDisabled={this.state.step === 4 && this.state.type === "repair" && this.state.images.length === 0 && !this.state.thumbnailImage} colorScheme={(this.state.number || this.state.step !== 0) ? "green" : 'gray'} onClick={() => {
                        if(this.state.step === 0) {
                            this.setState({ step: 1 })
                        } else if(this.state.step === 1) {
                            this.setState({ step: 2 })
                        } else if(this.state.step === 2) {
                            this.setState({ step: 3 })
                        } else if(this.state.step === 3) {
                            this.setState({ step: 4 })
                        } else if(this.state.step === 4) {
                            if(this.state.type === "inventory") {
                                if(this.state.thumbnailImage && this.state.boxImage && this.state.papersImage && this.state.serialNumberImage && this.state.behindImage && this.state.leftImage && this.state.rightImage) {
                                    if(this.state.thumbnailImageManual || this.state.boxImageManual || this.state.papersImageManual || this.state.serialNumberImageManual || this.state.behindImageManual || this.state.leftImageManual || this.state.rightImageManual) {
                                        this.unverifiedAlertFunctions.onOpen()
                                    } else {
                                        this.setState({ step: 5 })
                                    }
                                } else {
                                    this.missingAlertFunctions.onOpen()
                                }
                            } else {
                                if(this.state.thumbnailImage) {
                                    this.setState({ step: 5 })
                                } else {
                                    this.missingRestaurationFunctions.onOpen()
                                }
                            }
                        } else if(this.state.step === 5) {
                            this.setState({ saveLoading: true })

                            fetch(window.watchstorage.backendUrl + "/api/v1/watches", {
                                method: "POST",
                                credentials: "include",
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${global.WatchStorage.session.token}`
                                },
                                body: JSON.stringify({
                                    ...this.state,
                                    manualImages: this.state.thumbnailImageManual || this.state.boxImageManual || this.state.papersImageManual || this.state.serialNumberImageManual || this.state.behindImageManual || this.state.leftImageManual || this.state.rightImageManual
                                })
                            }).then(async result => {
                                if(result.status === 201) {
                                    setTimeout(() => this.setState({ open: false }), 1000)

                                    this.props.refresh()

                                    if(this.state.type === "repair") {
                                        const data = await result.json()

                                        fetch(window.watchstorage.backendUrl + "/api/v1/watchServices", {
                                            method: "POST",
                                            credentials: "include",
                                            headers: {
                                                'Content-Type': 'application/json',
                                                'Authorization': `Bearer ${global.WatchStorage.session.token}`
                                            },
                                            body: JSON.stringify({
                                                watchId: data.id,
                                                generalServices: this.state.serviceMap,
                                                restaurationServices: this.state.restaurationMap,
                                                dialServices: this.state.dialMap,
                                                optionalServices: this.state.optionalMap,
                                                images: this.state.images,
                                                consultation: this.state.consultation,
                                                description: this.state.description,
                                                telephone: this.state.telephone
                                            })
                                        }).then(async res => {
                                            if(res.status === 201) {
                                                window.toast({
                                                    title: 'Uhr gespeichert & Service angefragt!',
                                                    description: 'Wir haben deine Anfrage erhalten und werden und schnellstmöglich bei dir melden.',
                                                    status: 'success',
                                                    duration: 9000,
                                                    isClosable: true,
                                                })
                                            } else {
                                                window.toast({
                                                    title: 'Ein Fehler ist aufgetreten.',
                                                    description: res.statusText,
                                                    status: 'error',
                                                    duration: 9000,
                                                    isClosable: true,
                                                })

                                                console.log(res)
                                            }
                                        })
                                    } else {
                                        window.toast({
                                            title: 'Uhr gespeichert!',
                                            description: 'Die Uhr wurde erfolgreich erstellt und gespeichert.',
                                            status: 'success',
                                            duration: 9000,
                                            isClosable: true,
                                        })
                                    }
                                } else {
                                    window.toast({
                                        title: 'Ein Fehler ist aufgetreten.',
                                        description: result.statusText,
                                        status: 'error',
                                        duration: 9000,
                                        isClosable: true,
                                    })

                                    console.log(result)
                                }
                            }).catch(console.error)
                        }
                    }} mr={3}>{this.state.step === 5 ? (this.state.type === "inventory" ? "Uhr abspeichern" : "Service/Restauration anfragen") : "Weiter"}</Button></Tooltip>
                </ModalFooter>}
            </ModalContent>
        </Modal>
    }
}

const getShortCondition = condition => {
    switch(condition) {
        case "new": return "Neu"
        case "nos": return "New old Stock"
        case "very_good": return "Sehr gut"
        case "good": return "Gut"
        case "sufficient": return "Ausreichend"
        case "deficient": return "Mangelhaft"
    }
}

const StyledPictureContainer = styled.div`
    .responsiveSub {
      margin-right: 16px;
    }
  
    @media screen and (max-width: 799px) {
        flex-direction: column;
      
        .responsiveSub {
            margin-bottom: 24px;
        }
    }
`
const StyledPictureParent = styled.div`
    display: grid;
    grid-template-columns: 100px 1fr;
  
  @media screen and (max-width: 799px) {
    grid-template-columns: 1fr;
  }
`


export const PictureComponent = props => {
    return <Card>
        <StyledPictureParent>
            <Image
                src={props.image || props.placeholder}
                borderRadius='lg'
                height={100}
                width="100%"
                objectFit="cover"
                style={{ filter: props.image ? "none" : "grayscale(100%)" }}
            />
            <CardBody>
                <StyledPictureContainer style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "space-between", height: "100%" }}>
                    <div className="responsiveSub">
                        <p style={{ fontWeight: "bold", fontSize: "18px" }}>{props.title}</p>
                        <p>{props.description}</p>
                    </div>
                    <ButtonGroup spacing='2'>
                        <Button leftIcon={<HiCamera />} variant='solid' colorScheme='blue' onClick={() => props.takePhoto(props.field)}>
                            Kamera
                        </Button>
                        <Button leftIcon={<HiUpload style={{ marginInlineEnd: 4 }} />} variant='ghost' colorScheme='gray' onClick={() => {
                            props.setCurrentPhotoType(props.field)
                            props.fileUpload().current.click()
                        }}>
                            Hochladen
                        </Button>
                        <Button leftIcon={<HiTrash style={{ marginInlineEnd: 0 }} />} variant='ghost' colorScheme='red' onClick={() => {
                            props.setImageNull()
                        }}></Button>
                    </ButtonGroup>
                </StyledPictureContainer>
            </CardBody>
        </StyledPictureParent>
    </Card>
}

function UnverifiedImageAlertDialog(props) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef()

    useEffect(() => {
        props.functions({ isOpen, onOpen, onClose })
    }, [])

    return (
        <>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Hochgeladene Bilder erkannt
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Du hast nicht für alle Bilder ein neues Foto geschossen - hochgeladene Bilder sorgen dafür, dass deine Uhr nicht automatisch verifiziert werden kann. Möchtest du trotzdem fortfahren?
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Abbrechen
                            </Button>
                            <Button colorScheme='orange' onClick={() => {
                                onClose()
                                props.continue()
                            }} ml={3}>
                                Trotzdem fortfahren
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

function MissingImageAlertDialog(props) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef()

    useEffect(() => {
        props.functions({ isOpen, onOpen, onClose })
    }, [])

    return (
        <>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Fehlende Bilder
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Du hast nicht alle Bilder angegeben - deine Uhr kann daher in der Regel nicht verifiziert werden. Möchtest du trotzdem fortfahren?
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Abbrechen
                            </Button>
                            <Button colorScheme='orange' onClick={() => {
                                onClose()
                                props.continue()
                            }} ml={3}>
                                Trotzdem fortfahren
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

function MissingImageRestaurationDialog(props) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef()

    useEffect(() => {
        props.functions({ isOpen, onOpen, onClose })
    }, [])

    return (
        <>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Fehlende Bilder
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Du hast kein Vorderbild deiner Uhr angegeben. In der Übersicht wird für deine Uhr daher ein Platzhalterbild verwendet. Möchtest du fortfahren?
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Abbrechen
                            </Button>
                            <Button colorScheme='orange' onClick={() => {
                                onClose()
                                props.continue()
                            }} ml={3}>
                                Trotzdem fortfahren
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

const ResponsiveGrid = styled.div`
    grid-template-columns: 1fr 1fr 1fr;
  
    @media screen and (max-width: 799px) {
        grid-template-columns: 1fr 1fr;
    }
  @media screen and (max-width: 699px) {
    grid-template-columns: 1fr;
  }
`

const ResponsiveTitle = styled.div`
  grid-column-start: 1;
  grid-column-end: 4;
  
  @media screen and (max-width: 799px) {
      grid-column-end: 3;
  }
  
  @media screen and (max-width: 599px) {
    grid-column-end: 2;
  }
`

const ResponsiveSummary = styled.div`
  grid-template-columns: 1fr 1fr;
  @media screen and (max-width: 799px) {
    grid-template-columns: 1fr;
  }
`


const StyledPictureParentService = styled.div`
    display: grid;
    grid-auto-flow: row;
`


export const PictureComponentService = props => {
    return <Card>
        <StyledPictureParentService>
            <Image
                src={props.image}
                borderRadius='lg'
                height={140}
                width="100%"
                objectFit="cover"
                style={{ filter: props.image ? "none" : "grayscale(100%)" }}
            />
            <div style={{ padding: 8 }}>
                <Textarea
                    placeholder="Schadensbeschreibung"
                    size="lg"
                    resize="none"
                    value={props.description}
                    onChange={e => props.onChange(e.target.value)}
                />
            </div>
            <Button isDisabled={props.isDisabled} leftIcon={<HiTrash style={{ marginInlineEnd: 0 }} />} variant='ghost' colorScheme='red' onClick={() => {
                props.setImageNull()
            }}></Button>
        </StyledPictureParentService>
    </Card>
}

export const serviceMap = {
    watchNotRunning: "Uhr läuft nicht mehr",
    gearDeviation: "Starke Gangabweichungen",
    workFunction: "Werkfunktionen beeinträchtigt",
    waterProofness: "Wasserdichtigkeit",
    waterDamage: "Wasserschaden",
    housingDamage: "Schäden an Gehäuse und/oder Band",
    aesthetics: "Ästhetik (Kratzer, Abnutzung, Sturz etc.)",
    dirt: "Verschmutzung"
}

export const restaurationMap = {
    ultrasound: "Nur Reinigung von Band und Gehäuse im Ultraschall",
    housingRefreshment: "Leichte Auffrischung von Band und Gehäuse",
    housingPolish: "Bitte um Politur von Band und Gehäuse (leichte bis mittlere Kratzer werden entfernt)",
    housingRestauration: "Restauration von Band und Gehäuse (neue Stifte / Lasern etc)"
}

export const dialMap = {
    brightening: "Wiederaufhellung (Superluminova, nicht leuchtende oder moderne kurz leuchtende Pigmente)",
    illuminantRestauration: "Bitte um Verwendung von altem Leuchtmittel zur Wiederherstellung",
    dangerousIlluminantRestauration: "Restaurierung alter gefährlicher Leuchtstoffe (Altbestand)",
    illuminantRemoval: "Entfernung von altem Leuchtstoff",
    dialRestauration: "Zifferblattrestaurierung (Entfernen von Flecken, Kratzern, Korrekturen am Zifferblatt",
    clockHandRepainting: "Volle Uhrzeigerneulackierung",
    handAging: "Alterung der Zeiger/des Zifferblatts (nur in Verbindung mit Neulackierung oder Wiederaufhellung)",
    modernHandBrightening: "Zeigerwiederaufhellung mit modernen Pigmenten (kostenlos in Kombination mit Zifferblattwiederaufhellung)",
    tritiumHandBrightening: "Zeigerwiederaufhellung mit Tritium Pigmenten (kostenlos in Kombination mit Zifferblattwiederaufhellung)"
}

export const optionalMap = {
    photography: "Detaillierte Fotografie von Uhrenkomponenten plus 3 druckfertige Porträts mit Studiolicht (Bilder in maximaler Auflösung)"
}
